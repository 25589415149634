// src/App.js
import React from 'react';
import ImageList from './ImageList';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <ImageList />
    </div>
  );
};

export default App;
