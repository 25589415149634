// src/ImageList.js
import React, { useState, useEffect } from 'react';
import ImageCard from './ImageCard';

const ImageList = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch('https://your-server.com/images.json')
      .then(response => response.json())
      .then(data => setImages(data));
  }, []);

  return (
    <div className="image-list">
      {images.map((image, index) => (
        <ImageCard key={index} image={image} />
      ))}
    </div>
  );
};

export default ImageList;
