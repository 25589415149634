// src/ImageCard.js
import React from 'react';

const ImageCard = ({ image }) => {
  return (
    <div className="image-card">
      <img src={image.url} alt={image.title} />
      <h2>{image.title}</h2>
      <a href={image.url} target="_blank" rel="noopener noreferrer">
        {image.url}
      </a>
    </div>
  );
};

export default ImageCard;
